import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useMain from '../../../../hooks/useMain';
import DigitalWatch from '../../../common/digitalWatch';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';

const Contest = () => {
	const { initData, uiInfo } = useMain();
	const { isLessThanMD } = uiInfo;
	const { end, start } = initData.contests.millionairesChallenge;
	const [isFinished, setIsFinished] = useState(Date.now() >= end);
	if (Date.now() < start) return null;

	return (
		<div className='row'>
			<div className='col-sm-12'>
				<Link to='/dashboard/bonus/millionairesChallenge'>
					<TextCard
						className='millionairesChallenge position-relative'
						text={
							<div className='row py-4 py-lg-3 gy-5 align-items-center text-light'>
								<div className='col-lg-4 col-12 text-center'>
									<Image
										className={`w-${isLessThanMD ? '50' : '50'}`}
										fileName='millionaires/club.webp'
									/>
								</div>
								<div className='col-lg-4 col-12 text-center'>
									<Image
										className={`mb-4 w-${isLessThanMD ? '100' : '75'}`}
										fileName='millionaires/logo.webp'
									/>
									{isFinished ? (
										<h4>Event is ended.</h4>
									) : (
										<>
											<div>
												<div>Remaining Time:</div>
												<DigitalWatch
													className='d-inline-block'
													onComplete={() => setIsFinished(true)}
													date={end}
												/>
											</div>
										</>
									)}
								</div>

								<div className='col-lg-4 col-12 text-center'>
									<div>
										<Image
											className={`${isLessThanMD ? 'w-50 mb-5' : 'w-50'}`}
											fileName='millionaires/lottery.webp'
										/>
									</div>
								</div>
								<Image
									className={`position-absolute bottom-0 end-0 ${
										isLessThanMD ? 'w-100' : 'w-25'
									}`}
									fileName='millionaires/gift.webp'
								/>
							</div>
						}
					/>
				</Link>
			</div>
		</div>
	);
};

export default Contest;
