import React from 'react';
import Clock from 'react-live-clock';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useElemSize from '../../hooks/useElemSize';
import useMain from '../../hooks/useMain';
import userRank from '../../utils/userRank';
import BreadcrumbsTabs from '../common/breadcrumbsTabs';
import Image from '../common/image';

const MainPanelHeader = ({ crumbs, name }) => {
	const { userData, initData } = useMain();
	const [ref] = useElemSize('mainPanelHeader');
	const rank = userRank(userData, initData);
	useDocumentTitle(['Dashboard', name]);

	return (
		<header
			ref={ref}
			className='card myCards d-none d-md-block'
			style={{
				backgroundColor: '#edf0f4',
			}}>
			<div className='card-body'>
				<div className='d-flex align-items-center'>
					<div className=''>
						<BreadcrumbsTabs
							root='Dashboard'
							crumbs={crumbs}
						/>
					</div>
					<div className='mx-auto'>
						{rank && (
							<div className='d-flex justify-content-evenly align-items-center'>
								<Image
									fileName={`ranks/${rank.name}.svg`}
									height='55px'
									className='me-2'
								/>
								<div>
									<b>{rank.title}</b>
								</div>
							</div>
						)}
					</div>

					<div>
						<Clock
							format='dddd, MMMM Do, YYYY, h:mm:ss A'
							ticking={true}
							// timezone={'UTC'}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default MainPanelHeader;
