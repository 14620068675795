import React from 'react';
import { usePagination, DOTS } from '../../hooks/usePagination';

const Pagination = (props) => {
	const {
		onPageChange,
		totalCount,
		siblingCount = 1,
		currentPage,
		pageSize,
		customClass,
		activeClass,
	} = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});
	let lastPage = paginationRange[paginationRange.length - 1];

	if (currentPage === 0 || paginationRange.length < 2) return null;

	const onNext = () => {
		if (currentPage === lastPage) return;
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		if (currentPage === 1) return;
		onPageChange(currentPage - 1);
	};

	return (
		<ul className={`pagination ${customClass}`}>
			<li
				className='page-item'
				onClick={onPrevious}>
				<span className='page-link'>&laquo;</span>
			</li>
			{paginationRange.map((pageNumber, key) => {
				if (pageNumber === DOTS) {
					return (
						<li
							key={key}
							className='page-item'>
							<span className='page-link'>&#8230;</span>
						</li>
					);
				}
				return (
					<li
						key={key}
						className={
							pageNumber === currentPage ? `page-item ${activeClass}` : 'page-item'
						}
						onClick={() => onPageChange(pageNumber)}>
						<span className='page-link'>{pageNumber}</span>
					</li>
				);
			})}
			<li
				className={'page-item'}
				onClick={onNext}>
				<span className='page-link'>&raquo;</span>
			</li>
		</ul>
	);
};

export default Pagination;
