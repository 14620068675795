import React from 'react';
import Image from './../../common/image';

const HeaderImage = ({ tab }) =>
	tab.headerImage && tab.active ? (
		<div className='row'>
			<div className='col'>
				<div className='headerImageWrapper mb-4'>
					<Image
						className='myImages headerImage w-100'
						fileName={`headers/${tab.id}.jpg`}
					/>

					<Image
						className='myImages headerImageShadow w-100 d-none d-md-block'
						fileName={`headers/${tab.id === 'home' ? 'home' : 'raw'}.jpg`}
					/>
				</div>
			</div>
		</div>
	) : null;

export default HeaderImage;
