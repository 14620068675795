import React from 'react';
import { numberWithCommas, subString } from '../../../utils/helpers';
import Image from '../../common/image';

const MillionairesGlobalTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>Rank</th>
						<th>UID</th>
						<th>Email</th>
						<th>Balance</th>
						<th>Score</th>
						<th>Prize</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr
							className='align-middle'
							key={key}>
							<td>{getRank(d.no)}</td>
							<td>{d.uid}</td>
							<td>{subString(d.email, 8, 6, '.')}</td>
							<td>
								<b>$</b>
								<span>{numberWithCommas(d.balance / 1e6)}</span>
							</td>
							<td>
								<div className='d-flex align-items-center justify-content-center'>
									<span>{d.points}</span>
									<Image
										className='ms-2'
										height='30px'
										fileName='millionaires/point1.svg'
									/>
								</div>
							</td>
							<td>
								<b>$</b>
								<span>{numberWithCommas(d.prize / 1e6)}</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const getRank = (no) => {
	if (no > 10) return no;
	return (
		<Image
			height='30px'
			fileName={`millionaires/globalRanks/${no}.svg`}
		/>
	);
};

export default MillionairesGlobalTable;
