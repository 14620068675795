import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMain from '../../hooks/useMain';
import http from '../../services/httpService';
import Image from '../common/image';
import Preloader from '../common/preloader';
import InfoModal from '../dashboard/ingredients/infoModal';
import BinaryTreeGraph from '../dashboard/tabs/genealogy/tree/binaryTree';
import convertToD3Structure from '../dashboard/tabs/genealogy/tree/normalizeTreeData';
import GetUserForm from './users/getUserForm';

const UserTree = () => {
	const initialMaxDepth = 2;
	useDocumentTitle(['User Tree']);
	const { initData } = useMain();
	const [isLoading, setIsLoading] = useState(false);
	const [rawData, setRawData] = useState(null);
	const [treeData, setTreeData] = useState(null);
	const [user, setUser] = useState(null);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [isFull, setIsFull] = useState(false);
	const cardRef = useRef();

	if (!initData.domains) return <Preloader />;

	const handleGetUser = async (formData) => {
		try {
			setIsLoading(true);
			setRawData(null);
			setUser(null);
			setTreeData(null);
			const { data } = await http.getUserTree(formData);
			toast.success(data.message);
			const { userDownlines, userData } = data;
			setRawData(data);
			setUser(userData);
			setTreeData(
				convertToD3Structure(
					userDownlines,
					{
						...userData,
						...{
							name: userData.bemchain?.wallet,
							id: userData.bemchain?.uid,
							active: true,
							mmiIsActive: userData.mmi?.isActive,
							mmpDate: userData.date,
							mmiDate: userData.mmi?.activationDate,
							mmiStartAmount: userData.mmi?.installments[0]?.amount,
						},
					},
					initialMaxDepth
				)
			);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
		}
	};

	const handleModalShow = (data) => {
		setModalBody(
			<>
				{!data.email ? (
					<div className='centerAll'>
						<Image
							height='30px'
							className='me-2'
							fileName='icons/bemchain.png'
						/>
						<div>Only Bemchain User</div>
					</div>
				) : data.active === 'No' ? (
					<div>
						<div className='centerAll'>
							<Image
								height='30px'
								className='me-1'
								fileName='icons/warn.svg'
							/>
							<div>User has no active package</div>
						</div>
						<div className='text-center'>
							<b>Email: </b> <span>{data.email}</span>
						</div>
					</div>
				) : (
					<>
						<div className='d-flex justify-content-center align-items-center mb-3'>
							<Image
								className='me-3'
								height='45px'
								fileName={`diamonds/${data.card}.png`}
							/>
							<Image
								height='45px'
								fileName={`diamonds/mmi_${data.mmiIsActive}.png`}
							/>
						</div>
						<div className='row text-break'>
							<div className='col-6'>
								<div>
									<b>Email: </b> <span>{data.email}</span>
								</div>
								<div>
									<b>UID: </b> <span>{data.id}</span>
								</div>
								<div>
									<b>MMP Date: </b>{' '}
									<span>
										{data.mmpDate
											? new Date(data.mmpDate).toLocaleDateString()
											: 'Inactive'}
									</span>
								</div>
							</div>
							<div className='col-6'>
								<div>
									<b>MMP Card: </b> <span>{data.card}</span>
								</div>
								<div>
									<b>MMP Value: </b>{' '}
									<span>
										{data.active
											? `$${data.currentPackageValue / 1e6}`
											: 'Inactive'}
									</span>
								</div>
								<div>
									<b>MMI Date: </b>{' '}
									<span>
										{data.mmiDate
											? new Date(data.mmiDate).toLocaleDateString()
											: 'Inactive'}
									</span>
								</div>
								<div>
									<b>MMI Start Amount: </b>{' '}
									<span>
										{data.mmiStartAmount
											? `$${data.mmiStartAmount / 1e6}`
											: 'Inactive'}
									</span>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
		setModalShow(true);
	};

	const handleFullScreen = () => {
		if (cardRef.current) {
			const element = cardRef.current;
			element.classList.add('fullScreen');
			setIsFull(true);
		}
	};

	const handleNormalScreen = () => {
		if (cardRef.current) {
			const element = cardRef.current;
			element.classList.remove('fullScreen');
			setIsFull(false);
		}
	};

	return (
		<div className='container my-4'>
			<div className='card myCards'>
				<div className='row g-4 my-2'>
					<div className='col-12'>
						<div className='fw-bold fs-3 text-center'>User Network Tree</div>
					</div>
					<div className='offset-lg-2 col-lg-8'>
						<div className='fw-bold mb-4 text-center'>Get User Tree</div>
						<GetUserForm
							user={user}
							isLoading={isLoading}
							onSubmit={handleGetUser}
							userMainProps={initData.userMainProps}
						/>
					</div>
				</div>
				<hr className='mb-1' />
				<div style={{ minHeight: '30vh' }}>
					{treeData && (
						<>
							<div
								ref={cardRef}
								className='row p-4'>
								{!isFull && (
									<div className='col-12 text-center mb-4'>
										<button
											className='btn btn-sm btn-warning'
											onClick={handleFullScreen}>
											full screen
										</button>
									</div>
								)}
								<div className='col-12'>
									<BinaryTreeGraph
										rawData={[
											...(rawData?.userDownlines.left || []),
											...(rawData?.userDownlines.right || []),
										]}
										initialTreeData={treeData}
										onLabelClick={handleModalShow}
										maxDepth={1}
									/>
								</div>
							</div>
							<InfoModal
								show={modalShow}
								onClose={() => setModalShow(false)}
								title={'User Info'}
								body={modalBody}
							/>
							{isFull && (
								<button
									type='button'
									className='btn-close back-btn'
									onClick={handleNormalScreen}></button>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserTree;
