import * as bootstrap from 'bootstrap';
import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import useMain from '../hooks/useMain';
import config from './../config';
import http from './../services/httpService';
import Image from './common/image';

const items = {
	app: {
		navLinks: [
			{ name: 'Home', to: '/' },
			{ name: 'Dashboard', to: '/dashboard' },
			{ name: 'About', to: '/about' },
			{ name: 'Crowdfunding', to: '/crowdfunding' },
			{ name: 'Market Maker', to: '/marketmaker' },
			{ name: 'MMP', to: '/mmpworld' },
			{ name: 'Risk Management', to: '/risks' },
			{ name: 'MMI', to: '/mmi' },
		],
		hashLinks: [{ name: 'FAQ', to: '/#faq' }],
	},
	backOffice: {
		navLinks: [
			{ name: 'Home', to: '/' },
			{ name: 'Blogs', to: '/blogs' },
			{ name: 'Resources', to: '/resources' },
			{ name: 'KYC', to: '/kyc' },
			{ name: 'Withdrawals', to: '/withdrawals' },
			{ name: 'Tickets', to: '/tickets' },
			{ name: 'Terminations', to: '/terminations' },
			{ name: 'Official Debts', to: '/debts' },
			{ name: 'Configs', to: '/configs' },
			{ name: 'Users', to: '/users' },
			{ name: 'Tree', to: '/tree' },
		],
		hashLinks: [],
	},
};

const NavBar = ({ isGlobal }) => {
	const { onLogout, onHashLinkClick, mediaQuery } = useMain();
	const navRef = useRef(null);

	const handleClick = () => {
		if (mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md') {
			const myCollapse = new bootstrap.Collapse(navRef.current);
			myCollapse.toggle();
		}
	};

	if (!isGlobal) return null;
	return (
		<nav
			id='myNav'
			className='navbar navbar-expand-xl navbar-light'>
			<div className='container-fluid mx-lg-4'>
				<Link
					className='navbar-brand'
					to='/'>
					<Image
						className='d-inline-block align-top'
						fileName='logo_org.png'
						height='50'
					/>
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					onClick={handleClick}>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div
					ref={navRef}
					className='collapse navbar-collapse'
					id='navbarSupportedContent'>
					<ul className='navbar-nav mx-auto mb-2 mb-lg-0'>
						{items[config.domain].navLinks.map((i, key) => (
							<li
								key={key}
								className='nav-item'>
								<NavLink
									onClick={handleClick}
									className='nav-link px-0'
									to={i.to}>
									{i.name}
								</NavLink>
							</li>
						))}
						{items[config.domain].hashLinks.map((i, key) => (
							<li
								key={key}
								className='nav-item'>
								<NavHashLink
									className='nav-link px-0'
									smooth
									scroll={(el) => onHashLinkClick(el)}
									to={i.to}>
									{i.name}
								</NavHashLink>
							</li>
						))}
					</ul>

					<ul className='navbar-nav align-items-center'>
						{http.getCurrentUser() ? (
							<li className='nav-item'>
								<div className='nav-link'>
									<button
										type='button'
										className='btn btn-sm btn-my-blue'
										onClick={onLogout}>
										<div className='d-flex align-items-center justify-content-center'>
											<i className='fa fa-power-off me-1'></i>
											<span>Logout</span>
										</div>
									</button>
								</div>
							</li>
						) : (
							<>
								<li className='nav-item'>
									<NavLink
										className='nav-link btnLink'
										to='/login'>
										<button
											type='button'
											onClick={handleClick}
											className='btn btn-sm btn-my-blue btn-nav'>
											<div className='d-flex align-items-center justify-content-center'>
												<i className='fas fa-user me-1'></i>
												<span>Login</span>
											</div>
										</button>
									</NavLink>
								</li>
								{config.domain === 'app' && (
									<li className='nav-item'>
										<NavLink
											className='nav-link btnLink'
											to='/'>
											<button
												type='button'
												onClick={handleClick}
												className='btn btn-sm btn-my-purple btn-nav'>
												<div className='d-flex align-items-center justify-content-center'>
													<i className='fas fa-user-plus me-1'></i>
													<span>Register</span>
												</div>
											</button>
										</NavLink>
									</li>
								)}
							</>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
