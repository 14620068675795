import React, { useState, useRef, useEffect } from 'react';
import Tree from 'react-d3-tree';
import CustomNode from './customNode';

const BinaryTreeGraph = ({ initialTreeData, onLabelClick, rawData }) => {
	const [treeData, setTreeData] = useState(initialTreeData);
	const treeContainer = useRef(null);
	const [dimensions, setDimensions] = useState({ width: 800, height: 300 });

	useEffect(() => {
		if (treeContainer.current) {
			setDimensions({
				width: treeContainer.current.offsetWidth,
				height: treeContainer.current.offsetHeight,
			});
		}
	}, []);

	function updateTreeData(node, targetName, newChildren) {
		if (node.name === targetName && node.children.length === 0)
			return { ...node, children: newChildren };
		const updatedChildren = node.children.map((child) =>
			updateTreeData(child, targetName, newChildren)
		);
		return { ...node, children: updatedChildren };
	}

	const renderCustomNode = ({ nodeDatum, toggleNode }) => (
		<CustomNode
			nodeDatum={nodeDatum}
			toggleNode={() => {
				if (nodeDatum.childrenCount !== 0 && nodeDatum.children.length === 0) {
					const realChildren = [];
					for (const direct of nodeDatum.data.directs) {
						const child = rawData.find((d) => d.wallet === direct);
						realChildren.push({
							name: child.wallet,
							data: child,
							childrenCount: child.directs.length,
							children: [],
						});
					}
					setTreeData((prevData) =>
						updateTreeData(prevData, nodeDatum.name, realChildren)
					);
				}
				toggleNode();
			}}
			onLabelClick={onLabelClick}
		/>
	);

	return (
		<div
			ref={treeContainer}
			style={{ width: '100%', height: '100vh' }}>
			<Tree
				data={treeData}
				translate={{ x: dimensions.width / 2, y: 50 }}
				orientation='vertical'
				renderCustomNodeElement={renderCustomNode}
				collapsible={true}
				nodeSize={{ x: 150, y: 200 }}
			/>
		</div>
	);
};

export default BinaryTreeGraph;
