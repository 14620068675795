import React, { useContext, useState } from 'react';
import Countdown from 'react-countdown';
import ReactCardFlip from 'react-card-flip';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { capitalize } from 'lodash';
import MainContext from './../../../context/mainContext';
import { numberWithCommas } from './../../../utils/helpers';
import styles from '../../../css/dashboard/ingredients.module.css';
import BalanceChart from './balanceChart';
import IncomeChart from './incomeChart';
import UsersChart from './usersChart';
import Image from '../../common/image';
import TransactionsTable from './transactionsTable';
import BalanceTable from './balanceTable';
import HistoryTable from './historyTable';
import WalletTransactiosTable from './walletTransactionsTable';
import InvestmentHistoryTable from './investmentHistoryTable';
import TransferHistoryTable from './transferHistoryTable';
import SellHistoryTable from './sellHistoryTable';
import WithdrawHistoryTable from './withdrawHistoryTable';
import DownlinesTable from './downlinesTable';
import MMIDownlinesTable from './mmiDownlinesTable';
import CMCTable from './cmcTable';
import Pagination from './../../common/pagination';
import ListGroup from './../../common/listGroup';
import CopyBtn from '../../common/copyBtn';
import BinaryTable from './binaryTable';
import IncomeTable from './incomeTable';
import { DoughnutChart } from '../../common/charts';
import GoProTable from './goProTable';
import DebtTable from './debtTable';
import { paginate } from '../../../utils/paginate';
import Radio from '../../common/radio';

const NormalCard = ({ content }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>{content}</div>
		</div>
	);
};

const TextCard = ({ className = '', titleClass = '', title = '', text }) => {
	return (
		<div className={`mb-4 card myCards ${className}`}>
			<div className='card-body'>
				{title && <h4 className={titleClass}>{title}</h4>}
				{text}
			</div>
		</div>
	);
};

const WalletCard = ({ wallet, title, amount, type, bcn_usdt_rate }) => {
	const InnerComp = (
		<div className={`${styles.walletImageWrapper}`}>
			<div className={`${styles.walletImage}`}>
				<div className={`mb-4 card ${styles.walletCards} ${styles[wallet]}`}>
					<div className='card-body'>
						<Image
							height={'50px'}
							fileName={`wallets/icons/${wallet}.svg`}
						/>
						<div className='mt-2 fs-6'>{title} Wallet</div>
					</div>

					<hr className='mx-2 my-0' />
					<div className='card-img-bottom'>
						<div className='py-1 px-2 text-end me-2'>
							<b className='fs-4'>
								{numberWithCommas(Number(amount.toFixed(2)))} USDT
							</b>
							<div style={{ fontSize: '12px' }}>
								{numberWithCommas(Number((amount * bcn_usdt_rate).toFixed(2)))} BCN
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Image
				className={`myImages ${styles.walletImageShadow} w-100 d-none d-md-block`}
				fileName={`wallets/${wallet}.jpg`}
			/> */}
		</div>
	);
	return type === 'main' || type === 'voucher' ? (
		<Link to={`/dashboard/walletHistory/${wallet}History`}>{InnerComp}</Link>
	) : (
		InnerComp
	);
};

const FlippingDebit = ({ card, text }) => {
	const [isFlipped, setIsFlipped] = useState(false);

	return (
		<ReactCardFlip isFlipped={isFlipped}>
			<div
				onClick={() => setIsFlipped((prev) => !prev)}
				className={`mmpCard text-navy`}>
				<Image
					className={'myImages w-100'}
					fileName={`cards/${card}.jpg`}
				/>
			</div>

			<div
				onClick={() => setIsFlipped((prev) => !prev)}
				className={`mmpCard text-navy`}>
				<Image
					className={'myImages w-100'}
					fileName={`cards/${card === 'white' ? 'white_' : ''}back.jpg`}
				/>
				<span
					style={{
						position: 'absolute',
						left: '50%',
						top: '85%',
						transform: 'translate(-50%, -50%)',
						textShadow: '-1px -1px 1px #fff, 1px 1px 1px #000',
					}}>
					{text}
				</span>
			</div>
		</ReactCardFlip>
	);
};

const DebitCard = ({ titleClass, card, activeStatus, totalInvestement, roi, bc, userType }) => {
	const referralLink =
		userType === 'bc'
			? `https://bemchain.io/register?${bc}`
			: `https://mmp.world/register?ref=${bc}`;
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<h4 className={titleClass}>
					<b>My Debit Card</b>
				</h4>
				<div className='row align-items-start mt-4'>
					<div className='col-xl-4'>
						<FlippingDebit
							card={card}
							text={bc}
						/>
					</div>
					<div className='col-xl-8'>
						<ul className='nav nav-fill mt-4'>
							<li className='nav-item'>
								<div className='borderRight'>
									My Investement: <b>{totalInvestement} $</b>
								</div>
							</li>
							<li className='nav-item'>
								<div className='borderRight'>
									Monthly ROI: <b>{roi} %</b>
								</div>
							</li>
							<li className='nav-item'>
								<div className='borderRight'>
									Card Type: <b>{capitalize(card)} Diamond Card</b>
								</div>
							</li>
							<li className='nav-item'>
								<div className=''>
									Status: <b>{activeStatus}</b>
								</div>
							</li>
						</ul>
						<hr />
						<div className='row mt-4'>
							<div className='col-sm-6 text-center'>
								<p className='mt-4'>
									My referral code: <br />
									<span className={''}>
										<b>{bc}</b>
									</span>
									<CopyBtn content={bc} />
								</p>
								<p className='mb-4'>
									My referral Link: <br />
									<span className=''>
										<b>{referralLink}</b>
									</span>
									<CopyBtn content={referralLink} />
								</p>
							</div>
							<div className='col-sm-6 text-center'>
								<QRCode
									value={referralLink}
									level={'Q'}
									size={150}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const CMCCard = ({ data }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<h4 className='text-center'>
					{' '}
					<Image
						height={'40'}
						fileName={'icons/cmc.png'}
					/>{' '}
					Latest Prices
				</h4>
				<CMCTable tickers={data} />
			</div>
		</div>
	);
};

const CryptoDepositHistoryCard = ({ data, pageSize, currentPage, itemsCount, onPageChange }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				{data.length > 0 ? (
					<>
						<HistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image
							height={'180px'}
							fileName={'icons/noData.svg'}
						/>
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const WalletHistoryCard = ({
	name,
	balance,
	bcn_balance,
	data,
	image,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<h4 className='d-flex align-items-center justify-content-center fs-1 mt-5 mb-3'>
					<Image
						className={'me-3'}
						height={'90px'}
						fileName={`icons/menu/${image}.svg`}
					/>
					<span>{name} Wallet</span>
				</h4>
				<div className='text-center fs-4'>Current Balance: {balance}</div>
				<div className='text-center'>({bcn_balance} BCN)</div>
				<hr className='my-4' />
				{data.length > 0 ? (
					<div className='mb-5'>
						<WalletTransactiosTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				) : (
					<div className='text-center mb-5'>
						<Image
							height={'180px'}
							fileName={'icons/noData.svg'}
						/>
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const GoProCard = ({
	title,
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	date,
	isFinished,
	setIsFinished,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<h4 className='text-center fs-1 mt-5 mb-3'>{title} Tournament</h4>

				<div className='text-center'>
					{isFinished ? (
						<h2>Final Result</h2>
					) : (
						<Countdown
							className='h2'
							onComplete={() => setIsFinished(true)}
							date={date}
						/>
					)}
				</div>

				<hr className='my-4' />
				{data.length > 0 ? (
					<div className='mb-5'>
						<GoProTable
							data={data}
							title={title}
						/>
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				) : (
					<div className='text-center mb-5'>
						<Image
							height={'180px'}
							fileName={'icons/noData.svg'}
						/>
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const InvestmentHistoryCard = ({ data, pageSize, currentPage, itemsCount, onPageChange }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				{data.length > 0 ? (
					<>
						<InvestmentHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image
							height={'180px'}
							fileName={'icons/noData.svg'}
						/>
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const TransferHistoryCard = ({ data, pageSize, currentPage, itemsCount, onPageChange }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				{data.length > 0 ? (
					<>
						<TransferHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image
							height={'180px'}
							fileName={'icons/noData.svg'}
						/>
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const SellHistoryCard = ({ data, pageSize, currentPage, itemsCount, onPageChange }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				{data.length > 0 ? (
					<>
						<SellHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image
							height={'180px'}
							fileName={'icons/noData.svg'}
						/>
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const WithdrawHistoryCard = ({ data, pageSize, currentPage, itemsCount, onPageChange }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				{data.length > 0 ? (
					<>
						<WithdrawHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image
							height={'180px'}
							fileName={'icons/noData.svg'}
						/>
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const DownlinesCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	userTypes,
	selectedUserType,
	sortColumn,
	onTypeSelect,
	onSort,
}) => {
	return (
		<div className='mb-4 card myCards'>
			<div className='card-body'>
				<h3 className='text-center my-3'>My Downlines</h3>
				<div className='row '>
					<div className='col-sm-2 mb-3'>
						<h4 className='text-center mb-2'>Filters</h4>
						<ListGroup
							customClass={`${styles.myListGroupItem}`}
							activeClass={styles.myListGroupItemActive}
							items={userTypes}
							selectedItem={selectedUserType}
							onItemSelect={onTypeSelect}
							textProperty='content'
						/>
					</div>
					<div className='col'>
						<DownlinesTable
							users={data}
							sortColumn={sortColumn}
							onSort={onSort}
						/>
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
						<p>
							Users Count: <b>{itemsCount}</b>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const MMIDownlinesCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	userTypes,
	selectedUserType,
	sortColumn,
	onTypeSelect,
	onSort,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<h3 className='text-center my-3'>My MMI Downlines</h3>
				<div className='row '>
					{/* <div className='col-sm-2 mb-3'>
						<h4 className='text-center mb-2'>Filters</h4>
						<ListGroup
							customClass={`${styles.myListGroupItem}`}
							activeClass={styles.myListGroupItemActive}
							items={userTypes}
							selectedItem={selectedUserType}
							onItemSelect={onTypeSelect}
							textProperty={'content'}
						/>
					</div> */}
					<div className='col-12'>
						<MMIDownlinesTable
							users={data}
							sortColumn={sortColumn}
							onSort={onSort}
						/>
					</div>
					<div className='d-flex flex-wrap align-items-center justify-content-between'>
						<p>
							Users Count: <b>{itemsCount}</b>
						</p>
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const BinaryCard = ({ data, pageSize, currentPage, itemsCount, onPageChange, chartData }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<h3 className='text-center my-3'>Binary Activity Statistics</h3>
				<hr />
				<div className='row align-items-center'>
					<div className='col-sm-5 mb-3'>
						<BalanceChart data={chartData} />
					</div>
					<div className='col'>
						<BinaryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
						<p>
							Active Days: <b>{itemsCount}</b>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const IncomeCard = ({ data, pageSize, currentPage, itemsCount, onPageChange, chartData }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<div className='row align-items-center'>
					<div className='col-sm-3 mb-3'>
						<DoughnutChart chartData={chartData} />
					</div>
					<div className='offset-sm-1 col'>
						<IncomeTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const LabelCard = ({ titleClass, title, text }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className='card-body'>
				<div className=''>
					<h4 className={titleClass}>{title}</h4>
					<p className='text-center'>{text}</p>
				</div>
			</div>
		</div>
	);
};

const CardRight = ({ color, icon, image, title, text }) => {
	return (
		<div className={`mb-4 card ${styles.myCard} ${styles[`${color}BG`]}`}>
			<div className='card-body'>
				<div className='nav align-items-center justify-content-between'>
					{icon && <i className={`${styles.fade5} ${styles.iconLG} ${icon}`}></i>}
					{image && (
						<Image
							fileName={image}
							height={'180px'}
						/>
					)}
					<div className='text-end'>
						<h4>{title}</h4>
						<p>{text}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const CardLeft = ({ color, icon, image, title, text }) => {
	return (
		<div className={`mb-4 card ${styles.myCard} ${styles[`${color}BG`]}`}>
			<div className='card-body'>
				<div className='nav align-items-center justify-content-between'>
					<div>
						<h4>{title}</h4>
						<p>{text}</p>
					</div>
					{icon && <i className={`${styles.fade5} ${styles.iconLG} ${icon}`}></i>}
					{image && (
						<Image
							fileName={image}
							height={'180px'}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const CardCenter = ({ color, titleColor, title, text }) => {
	return (
		<div className={`mb-4 card ${styles.myCard} ${styles[`${color}BG`]}`}>
			<div className='card-body'>
				<div className='text-center'>
					<h4 className={`${styles[titleColor]}`}>
						<b>{title}</b>
					</h4>
					<p>{text}</p>
				</div>
			</div>
		</div>
	);
};

const CardCenterHasTheme = ({ titleColor, title, text }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<div className='text-center'>
					<h4 className={`${styles[titleColor]}`}>
						<b>{title}</b>
					</h4>
					<div>{text}</div>
				</div>
			</div>
		</div>
	);
};

const IconCard = ({ color, icon, image, title, text }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<div className='nav align-items-center justify-content-between'>
					{icon && <i className={`${styles.icon} ${icon} ${styles[color]}`}></i>}
					{image && (
						<Image
							fileName={image}
							height={'60'}
						/>
					)}
					<div className='text-end'>
						<h4>{title}</h4>
						<p className={` ${styles[color]}`}>{text}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const BalanceChartCard = ({ data }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<h4 className='text-center'>Weekly Gained Balance</h4>
				<BalanceChart data={data} />
			</div>
		</div>
	);
};

const IncomeChartCard = ({ data }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<h4 className='text-center'>My Total Income</h4>
				<IncomeChart incomeData={data} />
			</div>
		</div>
	);
};

const UsersChartCard = ({ data }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<h4 className='text-center'>Monthly User Registers</h4>
				<UsersChart data={data} />
			</div>
		</div>
	);
};

const TransactionsCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	txTypes,
	selcetedTxType,
	handleTypeSelect,
}) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<h4 className='text-center'>
					{' '}
					<Image
						height={'50'}
						fileName={'icons/icons8_general_ledger_96px.png'}
					/>{' '}
					Transactions History
				</h4>
				<div className='row '>
					<div className='col-sm-2 mb-3'>
						<h4 className='text-center mb-2'>Filters</h4>
						<ListGroup
							customClass={`${styles.myListGroupItem}`}
							activeClass={styles.myListGroupItemActive}
							items={txTypes}
							selectedItem={selcetedTxType}
							onItemSelect={handleTypeSelect}
							textProperty={'content'}
						/>
					</div>
					<div className='col'>
						<TransactionsTable txs={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const BalanceCard = ({ data, pageSize, currentPage, itemsCount, onPageChange }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<h4 className='text-center'>
					{' '}
					<Image
						height={'50'}
						fileName={'icons/icons8_scales_96px.png'}
					/>{' '}
					Weeky Balance ({itemsCount} weeks of activity)
				</h4>
				<BalanceTable weeks={data} />
				<Pagination
					customClass={`mt-1 ${styles.myPagination}`}
					activeClass={styles.myActive}
					totalCount={itemsCount}
					pageSize={pageSize}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			</div>
		</div>
	);
};

const PoolCard = ({ titleColor, secondColor, pool }) => {
	const { theme } = useContext(MainContext);
	const {
		poolPercentage,
		poolTitle,
		inventory,
		condition,
		members,
		balance,
		percentage,
		rewardReceived,
		completed,
		share,
	} = pool;
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<div className='text-center'>
					<h3 className='mb-3'>
						<Image
							height={'45'}
							fileName={'icons/icons8_gold_pot_96px.png'}
							className={'me-2'}
						/>
						{poolTitle} Pool:
						<span className={`${styles[titleColor]}`}>
							{' '}
							<b>{poolPercentage}</b>{' '}
						</span>
						%
					</h3>
					<p>
						Pool Inventory:
						<span className={`${styles[titleColor]}`}> {inventory} </span>
						BCN
					</p>
					<p>
						Receipt Condition:
						<span className={`${styles[titleColor]}`}> {condition} </span>
						Balance
					</p>
					<p>
						This Month Total Members:
						<span className={`${styles[titleColor]}`}> {members} </span>
					</p>
					<div>
						<h4>Your {poolTitle} Pool Progress</h4>
						<div className={`mb-3 ${styles[secondColor]}`}>
							Total Balance:
							<span> {balance} </span>(<span> {percentage} </span>
							%)
						</div>
						<div className='progress'>
							<div
								className='progress-bar progress-bar-striped progress-bar-animated bg-info'
								role='progressbar'
								aria-valuenow={percentage}
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ width: percentage + '%' }}></div>
						</div>
						<p className='mt-3'>
							{poolTitle} Pool Completion:
							<Image
								height={'30'}
								fileName={`icons/${completed}.png`}
							/>
						</p>
						<p>
							{poolTitle} Pool Reward Received:
							<Image
								height={'30'}
								fileName={`icons/${rewardReceived}.png`}
							/>
						</p>
						{!rewardReceived && (
							<p>
								Your Share:
								<span className={`${styles[titleColor]}`}> {share}</span>
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const GoldenPoolCard = ({ titleColor, balance, percentage }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className='card-body'>
				<div className='text-center'>
					<Image
						height={'100'}
						fileName={'icons/icons8_bitcoin_accepted_96px.png'}
						className={'me-2'}
					/>
					<h3 className='mb-3'>Golden Reward</h3>
					<div>
						<h4 className='mb-3'>Your Golden Reward Progress</h4>
						<div className='progress'>
							<div
								className='progress-bar progress-bar-striped progress-bar-animated bg-success'
								role='progressbar'
								aria-valuenow={percentage}
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ width: percentage + '%' }}></div>
						</div>
						<div className={`mt-3 ${styles[titleColor]}`}>
							Total Balance:
							<span> {balance} </span>(<span> {percentage} </span>
							%)
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const DebtCard = ({ debt, pageSize, name }) => {
	const txTypes = [
		{
			_id: 0,
			content: 'All Transaction',
			fn: (txs) => txs,
		},
		{
			_id: 1,
			content: 'Paybacks',
			fn: (txs) => txs.filter((u) => u.action === 'Payback'),
		},
		{
			_id: 2,
			content: 'Reflected Debts',
			fn: (txs) => txs.filter((u) => u.action !== 'Payback'),
		},
	];
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedtxType, setSelectedtxType] = useState(txTypes[0]);
	const sorted = debt.history.sort((a, b) => (a.date > b.date ? -1 : 1));
	for (const i in sorted) sorted[i].no = Number(i) + 1;
	const filteredTxs = selectedtxType.fn(sorted);

	const data = paginate(filteredTxs, currentPage, pageSize);

	const handleTypeSelect = (txType) => {
		setSelectedtxType(txType);
		setCurrentPage(1);
	};

	return (
		<div className='card-body text-center'>
			<div className='fs-4'>{name} Debts</div>
			<div className='fs-5 mb-4'>
				Total Remaining {name} Debt: $ {debt.amount / 1e6}
			</div>
			<hr />
			{sorted.length > 0 ? (
				<>
					{txTypes.map((t, key) => (
						<Radio
							formClass='form-check-inline'
							checked={t._id === selectedtxType._id}
							key={key}
							name={`${name} transactions type`}
							id={t._id}
							label={t.content}
							onChange={() => handleTypeSelect(t)}
						/>
					))}
					<DebtTable data={data} />
					<Pagination
						customClass={`mt-1 ${styles.myPagination}`}
						activeClass={styles.myActive}
						totalCount={filteredTxs.length}
						pageSize={pageSize}
						currentPage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</>
			) : (
				<div className='text-center'>
					<Image
						height='150px'
						fileName='icons/noData.svg'
					/>
					<h4 className='mt-4'>No Data</h4>
				</div>
			)}
		</div>
	);
};

const FlippingMMICard = ({ mmi, bc }) => {
	const [isFlipped, setIsFlipped] = useState(false);
	const handleClick = () => setIsFlipped((prev) => !prev);
	return (
		<ReactCardFlip isFlipped={isFlipped}>
			<div
				onClick={handleClick}
				className='mmpCard text-navy'>
				<Image
					className='myImages w-100'
					fileName={`cards/${mmi.isActive ? 'mmi' : 'mmi_white'}.jpg`}
				/>
				<span
					style={{
						position: 'absolute',
						left: '50%',
						top: '85%',
						transform: 'translate(-50%, -50%)',
						textShadow: '-1px -1px 1px #fff, 1px 1px 1px #000',
					}}>
					{mmi.code}
				</span>
			</div>

			<div
				onClick={handleClick}
				className='mmpCard text-navy'>
				<Image
					className='myImages w-100'
					fileName={`cards/${mmi.isActive ? 'back' : 'white_back'}.jpg`}
				/>
				<span
					style={{
						position: 'absolute',
						left: '50%',
						top: '85%',
						transform: 'translate(-50%, -50%)',
						textShadow: '-1px -1px 1px #fff, 1px 1px 1px #000',
					}}>
					{bc}
				</span>
			</div>
		</ReactCardFlip>
	);
};

export {
	GoProCard,
	NormalCard,
	WalletCard,
	LabelCard,
	FlippingDebit,
	DebitCard,
	CMCCard,
	CryptoDepositHistoryCard,
	WalletHistoryCard,
	InvestmentHistoryCard,
	TransferHistoryCard,
	SellHistoryCard,
	WithdrawHistoryCard,
	DownlinesCard,
	MMIDownlinesCard,
	BinaryCard,
	IncomeCard,
	TextCard,
	CardRight,
	CardLeft,
	CardCenter,
	CardCenterHasTheme,
	IconCard,
	BalanceChartCard,
	IncomeChartCard,
	TransactionsCard,
	BalanceCard,
	PoolCard,
	GoldenPoolCard,
	UsersChartCard,
	DebtCard,
	FlippingMMICard,
};
