import { capitalize } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import useMain from '../../../hooks/useMain';
import { numberWithCommas } from '../../../utils/helpers';
import { FlippingDebit, FlippingMMICard, TextCard, WalletCard } from '../ingredients/cards';
import Contest from './homeDependencies/contest';
import Festivals from './homeDependencies/festival';

const Home = () => {
	const { initData, userData, dashboardBlogs } = useMain();
	const { mmi, card, investmentHistory } = userData;
	const { mmiConfig } = initData;

	const isAlertMode = userData.usedMaxCap / userData.maxCap >= initData.maxCapAlert;

	const wallets = Object.keys(initData.wallets);
	const bcn_usdt_rate = 1 / initData.bcn_price_usd;

	return (
		<>
			<Contest />
			<Festivals />
			<div className='row'>
				{wallets.map((w, key) => {
					return (
						<div
							key={key}
							className='col-xl-3 col-lg-6'>
							<WalletCard
								wallet={w}
								title={initData.wallets[w].name}
								type={initData.wallets[w].type}
								amount={userData.wallets[w].available / 1e6}
								bcn_usdt_rate={bcn_usdt_rate}
							/>
						</div>
					);
				})}
			</div>

			<div className='row'>
				<div className='col-lg-6'>
					<div className='row'>
						<div className='col-12'>
							<TextCard
								text={
									<>
										<div className='d-flex justify-content-center flex-wrap text-center fs-5'>
											<div className='me-3'>
												<span className='fw-bold'>Max Cap:</span>{' '}
												{numberWithCommas(userData.maxCap / 1e6)} $
											</div>
											<div className='me-3'>
												<span className='fw-bold'>Used Max Cap:</span>{' '}
												<span
													className={`text-${
														isAlertMode ? 'danger' : 'success'
													}`}>
													{userData.maxCap === 0
														? 0
														: (
																(userData.usedMaxCap * 100) /
																userData.maxCap
														  ).toFixed(2)}
													%
												</span>
											</div>
											<div>
												<span className='fw-bold'>Total Income:</span>{' '}
												{numberWithCommas(userData.usedMaxCap / 1e6)} $
											</div>
										</div>
										<hr />
										<div className='row mt-4'>
											<div className='col-xl-6 text-center'>
												<FlippingDebit
													card={userData.card}
													text={userData.bemchain.uid}
												/>
												<div className='my-3'>
													<div>
														<span className='fw-bold'>
															Package Value:
														</span>{' '}
														{userData.currentPackageValue / 1e6} $
													</div>
													<div>
														<span className='fw-bold'>Card:</span>{' '}
														{capitalize(userData.card)}
													</div>
													<div>
														<span className='fw-bold'>
															Active Investments:
														</span>{' '}
														{
															investmentHistory.filter(
																(i) => i.status === 'active'
															).length
														}
													</div>
													<div>
														<span className='fw-bold'>Class:</span>{' '}
														{userData.class}
													</div>
													<div>
														<span className='fw-bold'>
															Manager Level:
														</span>{' '}
														{capitalize(userData.managerRank)}
													</div>
												</div>
												<Link
													className='btn btn-primary mb-4'
													to={
														'/dashboard/investmentPackage/purchasePackage'
													}>
													MMP Package
												</Link>
											</div>
											<div className='col-xl-6 text-center'>
												<FlippingMMICard
													mmi={userData.mmi}
													bc={userData.bemchain.uid}
												/>
												<div className='my-3'>
													<div>
														<span className='fw-bold'>
															MMI Contract Period:
														</span>{' '}
														{mmi.isActive
															? mmi.contractPeriod + ' years'
															: 'Inactive'}
													</div>
													<div>
														<span className='fw-bold'>
															MMI Payment Method:
														</span>{' '}
														{mmi.isActive
															? mmiConfig.paymentMethod.find(
																	(m) =>
																		m.duration ===
																		mmi.paymentMethod
															  ).content
															: 'Inactive'}
													</div>
													<div>
														<span className='fw-bold'>
															MMI Increase Percent:
														</span>{' '}
														{mmi.isActive
															? mmi.increasePercent * 100 + '%'
															: 'Inactive'}
													</div>
													<div>
														<span className='fw-bold'>MMI Amount:</span>{' '}
														{mmi.isActive
															? '$' +
															  numberWithCommas(mmi.amount / 1e6) +
															  ' - ' +
															  mmiConfig.paymentMethod.find(
																	(m) =>
																		m.duration ===
																		mmi.paymentMethod
															  ).content
															: 'Inactive'}
													</div>
													<div>
														<span className='fw-bold'>
															Last Year Profit Rate:
														</span>{' '}
														32%
													</div>
												</div>
												<Link
													className='btn btn-primary'
													to={`/dashboard/mmi/${
														mmi.isActive
															? 'insuranceDashboard'
															: card === 'white'
															? 'purchaseInsurance'
															: 'migrate'
													}`}>
													MMI Package
												</Link>
											</div>
										</div>
									</>
								}
							/>
						</div>
					</div>
				</div>
				<div className='col-lg-6'>
					{dashboardBlogs && dashboardBlogs[0] && (
						<TextCard
							titleClass={'text-center mb-4'}
							title={'Latest News'}
							text={
								<div className='row align-items-center my-3'>
									<div className='col-sm-5 text-center'>
										<img
											loading='lazy'
											src={dashboardBlogs[0].thumbLink}
											className='w-100 rounded'
											alt={dashboardBlogs[0]._id}
											crossOrigin='true'
										/>
									</div>
									<div className='col-sm-7 text-center'>
										<h5>{dashboardBlogs[0].title}</h5>
										<p>
											{dashboardBlogs[0].description}
											<br />
											<Link
												to={{
													pathname: '/dashboard/news',
													state: {
														_id: dashboardBlogs[0]._id,
													},
												}}>
												Read More ...
											</Link>
										</p>
										<p className='fs-6 opacity-50'>
											{new Date(dashboardBlogs[0].date).toLocaleDateString()}
										</p>
									</div>
								</div>
							}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default Home;
