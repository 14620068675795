import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../../config';
import useMain from '../../../hooks/useMain';
import Image from '../../common/image';
import Spinner from '../../common/spinner';

function BlogCard({ blog, history, isLoading, onDelete }) {
	const { title, description, thumbLink, author, date, isPublished, _id, tags, domain } = blog;

	const { userData } = useMain();
	const isBlogMine = userData.blogs.includes(_id);

	const handleEdit = () => {
		history.push({
			pathname: '/blogs/edit',
			state: { _id },
		});
	};

	return (
		<div className='card myCards'>
			<Link to={`/blog/${_id}`}>
				<img
					loading='lazy'
					src={
						config.mode === 'prod'
							? thumbLink
							: thumbLink.replace('https://mmp.cab', 'http://127.0.0.1:3009')
					}
					className='card-img-top'
					alt={_id}
					crossOrigin='true'
				/>
			</Link>
			<div className='card-body'>
				<div className='card-title'>
					<div className='d-flex justify-content-center align-items-center'>
						<span className='h6 mb-0'>
							<b>{title}</b>
						</span>
						<Image
							className={'ms-2'}
							fileName={`${isPublished}.png`}
						/>
					</div>
				</div>

				<figure className='card-text text-center'>
					<blockquote className='blockquote'>
						<p>{description.substring(0, 15) + '...'}</p>
					</blockquote>
					<figcaption className='blockquote-footer'>
						{author.profile.fullName} <br />
						{new Date(date).toLocaleDateString()}
					</figcaption>
					<div className='d-flex justify-content-evenly align-items-center fs-6'>
						<div>
							Home:{' '}
							<i
								className={`fas fa-${
									tags.includes('home') ? 'check' : 'times'
								}`}></i>
						</div>
						<div>
							Dashboard:{' '}
							<i
								className={`fas fa-${
									tags.includes('dashboard') ? 'check' : 'times'
								}`}></i>
						</div>
					</div>
					<hr />
					<div className='d-flex justify-content-evenly align-items-center fs-6'>
						<div>
							MMP World:{' '}
							<i
								className={`fas fa-${
									domain.includes('mmp') ? 'check' : 'times'
								}`}></i>
						</div>
						<div>
							Bemchain:{' '}
							<i
								className={`fas fa-${
									domain.includes('bemchain') ? 'check' : 'times'
								}`}></i>
						</div>
					</div>
				</figure>
				<hr />
				<div className='d-flex justify-content-evenly'>
					<button
						onClick={handleEdit}
						className='btn btn-sm btn-my-navy'
						disabled={!isBlogMine || isLoading}>
						{isLoading ? <Spinner content={'loading'} /> : 'Edit'}
					</button>
					<button
						onClick={() => onDelete(_id)}
						className='btn btn-sm btn-my-navy'
						disabled={!isBlogMine || isLoading}>
						{isLoading ? <Spinner content={'loading'} /> : 'Delete'}
					</button>
				</div>
			</div>
		</div>
	);
}

export default BlogCard;
