import React, { useState } from 'react';
import { toast } from 'react-toastify';
import useMain from '../../../../hooks/useMain';
import http from '../../../../services/httpService';
import { roundUpMinutes } from '../../../../utils/helpers';
import { TextCard } from '../../ingredients/cards';
import ConfirmModal from '../../ingredients/confirmModal';
import SellCurrentDepositForm from './sellCurrentDepositForm';

const SellCurrentDeposit = () => {
	const { userData, initData, onUserDataChanged } = useMain();
	const [isSubmited, setIsSubmited] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const isBeingTerminated = userData.terminationRequest.isActive;
	const isTerminated = userData.terminationRequest.isApproved;

	const requestTime = userData.hasActiveSellRequest
		? new Date(roundUpMinutes(userData.sellRequests[0].date)).getTime()
		: 0;
	const counterDate = initData.sellGap + requestTime;
	const currentSellRequestAmount = userData.hasActiveSellRequest
		? userData.sellRequests[0].amount / 1e6
		: 0;

	const { maxSellMultiplier } = initData.managerRanks[userData.managerRank];

	const handleRequestTimerCompleted = async () => {
		try {
			const { data } = await http.getOnlyMe();
			onUserDataChanged(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const handleModalShow = (formData) => {
		setCurrentFormData(formData);
		const htmlOnSell = (
			<>
				<div>Exchange Current Deposit to USDT</div>
				<div>
					Amount: <b>{formData.amount}</b> USDT
				</div>
				<div>
					You will receive <b>${formData.amount * (1 - initData.sellFee)}</b> in your USDT
					Wallet.
				</div>
				<div>
					Approximate processing time: <b>{initData.sellGap / (60 * 60 * 1000)}</b> hours
				</div>
			</>
		);
		const htmlOnCancel = <div>Are you sure you want to cancel the current sell request?</div>;
		setModalBody(userData.hasActiveSellRequest ? htmlOnCancel : htmlOnSell);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response && !userData.hasActiveSellRequest) handleExchange();
		if (response && userData.hasActiveSellRequest) handleCancel();
	};

	const handleExchange = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.sellCurrentDeposit(currentFormData);
			toast.success(data.message);
			onUserDataChanged(data.data);
			setIsSubmited(true);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentFormData(null);
		}
	};

	const handleCancel = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.cancelSellCurrentDeposit({
				securityPassword: currentFormData.securityPassword,
			});
			toast.success(data.message);
			onUserDataChanged(data.data);
			setIsSubmited(true);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data) return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentFormData(null);
		}
	};

	return (
		<div className='row'>
			<div className='col-12 text-center'>
				<TextCard
					title={'Exchange Current Deposit to USDT'}
					titleClass={'fs-3'}
					text={
						<div className='row'>
							<div className='col-sm-8 offset-sm-2 col-12'>
								{userData.isUserActive || isTerminated ? (
									<SellCurrentDepositForm
										onFormChange={() => setIsSubmited(false)}
										isSubmited={isSubmited}
										isLoading={isLoading}
										hasSP={userData.hasSP}
										min={initData.minSell}
										max={Math.min(
											userData.wallets.currentDeposit.available / 1e6,
											(initData.cards[
												isTerminated
													? userData.lastCard
													: userData.totalCard
											].sellLimit *
												maxSellMultiplier) /
												1e6
										)}
										step={initData.stepSell}
										hasActiveSellRequest={userData.hasActiveSellRequest}
										walletBalance={
											userData.wallets.currentDeposit.available / 1e6
										}
										cardLimit={
											(initData.cards[
												isTerminated
													? userData.lastCard
													: userData.totalCard
											].sellLimit *
												maxSellMultiplier) /
											1e6
										}
										onSubmit={handleModalShow}
										onRequestTimerCompleted={handleRequestTimerCompleted}
										counterDate={counterDate}
										currentSellRequestAmount={currentSellRequestAmount}
										isTerminated={isTerminated}
									/>
								) : isBeingTerminated ? (
									<h2 className='text-danger py-5'>
										Your termination request is being reviwed, you will access
										Sell Current Deposit when termination is approved.
									</h2>
								) : (
									<h2 className='py-5'>
										Sell Current Deposit will be availabe only when you have an
										active package.
									</h2>
								)}
								<hr className='mt-4' />
								<div className='text-start mb-5'>
									<h5>Internal exchange rules:</h5>
									<ul>
										<li>
											{' '}
											The minimum selling amount is ${initData.minSell} per
											transaction
										</li>
										<li>
											{' '}
											User must successfully sell the order to proceed for the
											next sell order.
										</li>
										<li>
											{' '}
											Each transaction takes{' '}
											{initData.sellGap / (60 * 60 * 1000)} hours.
										</li>
										<li>
											{' '}
											Each transaction has a {initData.sellFee * 100}% fee.
										</li>
									</ul>
								</div>
								<ConfirmModal
									show={modalShow}
									onClose={handleModalClose}
									title={'Internal Exchange'}
									body={modalBody}
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default SellCurrentDeposit;
