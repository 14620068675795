import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { handleCopy } from '../../../utils/helpers';
import WalletRequestModalForm from './walletRequestModalForm';

function WalletRequestModal({ onClose, show, hasSP, isLoading, response, onRequestSubmit }) {
	const [showPK, setShowPK] = useState(false);
	return (
		<Modal
			centered
			show={show}
			onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Request Wallet</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!response ? (
					<WalletRequestModalForm
						hasSP={hasSP}
						isLoading={isLoading}
						onSubmit={onRequestSubmit}
					/>
				) : (
					<>
						<div className='text-center text-break'>
							<div>
								<span className='fw-bold'>Address:</span> {response.address}
							</div>
							<div className='my-3'>
								<span className='fw-bold'>Private Key:</span>{' '}
								{showPK ? response.pk : '*'.repeat(64)}
							</div>
						</div>
						<div className='d-flex justify-content-evenly align-items-center mt-4'>
							<button
								onClick={() => setShowPK(!showPK)}
								className='btn btn-my-navy'>
								{showPK ? 'Hide PK' : 'Show PK'}
							</button>
							<button
								onClick={() => handleCopy(response.pk)}
								className='btn btn-my-navy'>
								Copy PK
							</button>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default WalletRequestModal;
