import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { RiseLoader } from 'react-spinners';
import styles from '../../../../css/dashboard/ingredients.module.css';
import useMain from '../../../../hooks/useMain';
import useMillionairesResult from '../../../../hooks/useMillionairesResult';
import getCSSVars from '../../../../utils/getCSSVars';
import { paginate } from '../../../../utils/paginate';
import Image from '../../../common/image';
import Pagination from '../../../common/pagination';
import MillionairesClubTable from '../../ingredients/millionairesClubTable';
import MillionairesGlobalTable from '../../ingredients/millionairesGlobalTable';
import { numberWithCommas } from '../../../../utils/helpers';

function Bonus() {
	const pageSize = 10;
	const { initData, userData } = useMain();
	const { email, L, R } = userData;
	const { end, start, club, condition } = initData.contests.millionairesChallenge;
	const [timeIsFinished, setTimeIsFinished] = useState(Date.now() >= end);
	const [globalCurrentPage, setGlobalCurrentPage] = useState(1);
	const [clubCurrentPage, setClubCurrentPage] = useState(1);
	const [result] = useMillionairesResult();

	if (!result)
		return (
			<div className='h-50 d-flex justify-content-center align-items-center my-5'>
				<RiseLoader
					speedMultiplier={0.8}
					loading={true}
					size={20}
					sizeUnit='px'
					color={getCSSVars('my-blue')}
				/>
			</div>
		);

	const { globalResult, clubResult, me } = result;
	const { isClubMember, isParticipant } = me;

	const normalizedGlobalData = [...globalResult];
	for (const i in normalizedGlobalData) normalizedGlobalData[i].no = Number(i) + 1;
	const globalResultData = paginate(normalizedGlobalData, globalCurrentPage, pageSize);
	const myIndexInGolbalResult = normalizedGlobalData.findIndex((d) => d.email === email);

	const normalizedClubData = [...clubResult];
	for (const i in normalizedClubData) {
		normalizedClubData[i].no = Number(i) + 1;
		normalizedClubData[i].prize = club.prizes[i];
	}
	const clubResultData = paginate(normalizedClubData, clubCurrentPage, pageSize);
	const meInClubResult = normalizedClubData.find((d) => d.email === email);

	if (Date.now() < start)
		return (
			<div className='row mt-4'>
				<div className='col text-center'>
					<h4>Tournament will start in:</h4>
					<Countdown
						className='h2'
						onComplete={() => window.location.reload()}
						date={start}
					/>
				</div>
			</div>
		);
	return (
		<div className='mb-4 card myCards'>
			<div className='card-body'>
				<div className='row text-center'>
					{timeIsFinished ? (
						<h2>Time Is Finished</h2>
					) : (
						<div className='text-center'>
							<div className='fw-bold fs-3 mb-1 text-uppercase'>Remaining Time</div>
							<Countdown
								className='h2'
								onComplete={() => setTimeIsFinished(true)}
								date={end}
							/>
						</div>
					)}
				</div>

				{!isParticipant ? (
					<div className='row my-5'>
						<div className='offset-2 col-8 text-center'>
							<hr />
							<div>You are not participating in the contest</div>
							<hr />
						</div>
					</div>
				) : isClubMember ? (
					<div className='row'>
						<div className='offset-1 col-10'>
							<hr />
							<div className='row g-4'>
								<div className='col-12 text-center'>
									<div className='fw-bold'>
										You are paticipating in the Millionaires Club Contest
									</div>
								</div>
								<div className='col-lg-6 col-xl-3'>
									<div>
										Start Left Balance:{' '}
										<b>{numberWithCommas(me?.snapshot?.left / 1e6)} </b>
									</div>
									<div>
										Start Right Balance:{' '}
										<b>{numberWithCommas(me?.snapshot?.right / 1e6)} </b>
									</div>
								</div>
								<div className='col-lg-6 col-xl-3'>
									<div>
										Left Factor:
										<b> {numberWithCommas(meInClubResult.leftFactor / 1e6)} </b>
									</div>
									<div>
										Right Factor:{' '}
										<b>{numberWithCommas(meInClubResult.rightFactor / 1e6)} </b>
									</div>
								</div>
								<div className='col-lg-6 col-xl-3'>
									<div>
										Current Left Balance:
										<b> {numberWithCommas(L.totalValue / 1e6)} </b>
									</div>
									<div>
										Current Right Balance:{' '}
										<b>{numberWithCommas(R.totalValue / 1e6)} </b>
									</div>
								</div>
								<div className='col-lg-6 col-xl-3'>
									<div>
										Earned Left:
										<b> {numberWithCommas(meInClubResult.earnedLeft / 1e6)} </b>
									</div>
									<div>
										Earned Right:{' '}
										<b>{numberWithCommas(meInClubResult.earnedRight / 1e6)} </b>
									</div>
								</div>

								<div className='col-12 d-flex justify-content-center'>
									<div className='mx-3'>
										Score: <b>{meInClubResult.points} </b>
									</div>
									<div>
										Rank:{' '}
										<b>
											{meInClubResult.points > 0
												? meInClubResult.no
												: 'Uncharted'}{' '}
										</b>
									</div>
								</div>
							</div>
							<hr />
						</div>
					</div>
				) : (
					<div className='row'>
						<div className='offset-2 col-8'>
							<hr />
							<div className='row g-4'>
								<div className='col-12 text-center'>
									<div className='fw-bold'>
										You are paticipating in the Global Contest
									</div>
								</div>
								{!timeIsFinished && (
									<div className='col-12 text-center'>
										<div>Next Prizes Payment:</div>
										<Countdown
											// className='h2'
											onComplete={() => window.location.reload()}
											date={initData.prizeTime}
										/>
									</div>
								)}
								<div className='offset-md-1 col-md-4'>
									<div>
										Start Left Balance:{' '}
										<b>{numberWithCommas(me?.snapshot?.left / 1e6)} </b>
									</div>
									<div>
										Start Right Balance:{' '}
										<b>{numberWithCommas(me?.snapshot?.right / 1e6)} </b>
									</div>
								</div>
								<div className='offset-md-2 col-md-4'>
									<div>
										Current Left Balance:
										<b> {numberWithCommas(L.totalValue / 1e6)} </b>
									</div>
									<div>
										Current Right Balance:{' '}
										<b>{numberWithCommas(R.totalValue / 1e6)} </b>
									</div>
								</div>

								<div className='col-12 d-flex justify-content-center'>
									<div>
										Earned Balance:{' '}
										<b>
											{numberWithCommas(
												Math.min(
													L.totalValue - me?.snapshot?.left,
													R.totalValue - me?.snapshot?.right
												) / 1e6
											)}{' '}
										</b>
									</div>
									<div className='mx-3'>
										Score:{' '}
										<b>
											{numberWithCommas(
												parseInt(
													Math.min(
														L.totalValue - me?.snapshot?.left,
														R.totalValue - me?.snapshot?.right
													) / condition
												)
											)}{' '}
										</b>
									</div>
									<div className='mx-3'>
										Rank:{' '}
										{myIndexInGolbalResult >= 0 ? (
											<b>{myIndexInGolbalResult + 1} </b>
										) : (
											<span>uncharted</span>
										)}
									</div>
									{me.takenPrize && (
										<>
											Paid Prizes:{' '}
											<b>${numberWithCommas(me.takenPrize.prize / 1e6)}</b>
										</>
									)}
								</div>
							</div>
							<hr />
						</div>
					</div>
				)}

				<div className='d-flex flex-column'>
					<div className='row g-5 mb-5 order-1'>
						<div className='offset-lg-2 col-lg-8 text-center'>
							<div className='mb-5'>
								<Image
									fileName='millionaires/logo.webp'
									height='100px'
								/>
								<div className='text-center fw-bold fs-5 my-3 text-uppercase'>
									Global Contest
								</div>
								{globalResultData.length > 0 ? (
									<>
										<MillionairesGlobalTable data={globalResultData} />
										<div className='d-flex justify-content-center'>
											<Pagination
												customClass={`mt-1 ${styles.myPagination}`}
												activeClass={styles.myActive}
												totalCount={normalizedGlobalData.length}
												pageSize={pageSize}
												currentPage={globalCurrentPage}
												onPageChange={setGlobalCurrentPage}
											/>
										</div>
									</>
								) : (
									<div className='text-center mb-5'>
										<Image
											height='80px'
											fileName='icons/noData.svg'
										/>
										<h4 className='mt-4'>No Data Yet</h4>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className={`row g-5 mb-5 ${isClubMember ? 'order-0' : 'order-2'}`}>
						<div className='offset-lg-2 col-lg-8 text-center'>
							{clubResultData.length > 0 ? (
								<div className='mb-5'>
									<Image
										fileName='millionaires/club.webp'
										height='100px'
									/>
									<div className='text-center fw-bold fs-5 my-3 text-uppercase'>
										Millionaires Club Contest
									</div>
									<MillionairesClubTable data={clubResultData} />

									<div className='d-flex justify-content-center'>
										<Pagination
											customClass={`mt-1 ${styles.myPagination}`}
											activeClass={styles.myActive}
											totalCount={normalizedClubData.length}
											pageSize={pageSize}
											currentPage={clubCurrentPage}
											onPageChange={setClubCurrentPage}
										/>
									</div>
								</div>
							) : (
								<div className='text-center mb-5'>
									<Image
										height='180px'
										fileName='icons/noData.svg'
									/>
									<h4 className='mt-4'>No Data</h4>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Bonus;
