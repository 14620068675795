import React, { useRef, useState } from 'react';
import { RiseLoader } from 'react-spinners';
import useMain from '../../../../hooks/useMain';
import convertToD3Structure from './tree/normalizeTreeData';
import InfoModal from '../../ingredients/infoModal';
import BinaryTreeGraph from './tree/binaryTree';
import Image from '../../../common/image';

const NetworkView = () => {
	const initialMaxDepth = 2;
	const { userDownlines, userData } = useMain();
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [isFull, setIsFull] = useState(false);
	const cardRef = useRef();

	console.log('userDownlines :>> ', userDownlines);

	const handleModalShow = (data) => {
		setModalBody(
			<>
				{!data.email ? (
					<div className='centerAll'>
						<Image
							height='30px'
							className='me-2'
							fileName='icons/bemchain.png'
						/>
						<div>Only Bemchain User</div>
					</div>
				) : data.active === 'No' ? (
					<div>
						<div className='centerAll'>
							<Image
								height='30px'
								className='me-1'
								fileName='icons/warn.svg'
							/>
							<div>User has no active package</div>
						</div>
						<div className='text-center'>
							<b>Email: </b> <span>{data.email}</span>
						</div>
					</div>
				) : (
					<>
						<div className='d-flex justify-content-center align-items-center mb-3'>
							<Image
								className='me-3'
								height='45px'
								fileName={`diamonds/${data.card}.png`}
							/>
							<Image
								height='45px'
								fileName={`diamonds/mmi_${data.mmiIsActive}.png`}
							/>
						</div>
						<div className='row text-break'>
							<div className='col-6'>
								<div>
									<b>Email: </b> <span>{data.email}</span>
								</div>
								<div>
									<b>UID: </b> <span>{data.id}</span>
								</div>
								<div>
									<b>MMP Date: </b>{' '}
									<span>
										{data.mmpDate
											? new Date(data.mmpDate).toLocaleDateString()
											: 'Inactive'}
									</span>
								</div>
							</div>
							<div className='col-6'>
								<div>
									<b>MMP Card: </b> <span>{data.card}</span>
								</div>
								<div>
									<b>MMP Value: </b>{' '}
									<span>
										{data.active
											? `$${data.currentPackageValue / 1e6}`
											: 'Inactive'}
									</span>
								</div>
								<div>
									<b>MMI Date: </b>{' '}
									<span>
										{data.mmiDate
											? new Date(data.mmiDate).toLocaleDateString()
											: 'Inactive'}
									</span>
								</div>
								<div>
									<b>MMI Start Amount: </b>{' '}
									<span>
										{data.mmiStartAmount
											? `$${data.mmiStartAmount / 1e6}`
											: 'Inactive'}
									</span>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
		setModalShow(true);
	};

	if (!userDownlines)
		return (
			<div className='h-50 d-flex justify-content-center align-items-center'>
				<RiseLoader
					speedMultiplier={0.8}
					loading={true}
					size={20}
					sizeUnit='px'
					color='#7b8cff'
				/>
			</div>
		);

	const treeData = convertToD3Structure(
		userDownlines,
		{
			...userData,
			...{
				name: userData.bemchain.wallet,
				id: userData.bemchain.uid,
				active: true,
				mmiIsActive: userData.mmi.isActive,
				mmpDate: userData.date,
				mmiDate: userData.mmi?.activationDate,
				mmiStartAmount: userData.mmi?.installments[0]?.amount,
			},
		},
		initialMaxDepth
	);

	const handleFullScreen = () => {
		if (cardRef.current) {
			const element = cardRef.current;
			element.classList.add('fullScreen');
			setIsFull(true);
		}
	};

	const handleNormalScreen = () => {
		if (cardRef.current) {
			const element = cardRef.current;
			element.classList.remove('fullScreen');
			setIsFull(false);
		}
	};

	return (
		<>
			<div
				ref={cardRef}
				className='card myCards'>
				<div className='row p-4'>
					<div className='col-12 text-center'>
						<div className='fs-2 fw-bold text-purple'>Network View</div>
						{!isFull && (
							<button
								className='btn btn-sm btn-warning'
								onClick={handleFullScreen}>
								full screen
							</button>
						)}
						<hr className='mb-0' />
					</div>
					<div className='col-12'>
						<BinaryTreeGraph
							rawData={[
								...(userDownlines.left || []),
								...(userDownlines.right || []),
							]}
							initialTreeData={treeData}
							onLabelClick={handleModalShow}
							maxDepth={1}
						/>
					</div>
				</div>
			</div>
			<InfoModal
				show={modalShow}
				onClose={() => setModalShow(false)}
				title={'User Info'}
				body={modalBody}
			/>
			{isFull && (
				<button
					type='button'
					className='btn-close back-btn'
					onClick={handleNormalScreen}></button>
			)}
		</>
	);
};

export default NetworkView;
