import React from 'react';
import { Link } from 'react-router-dom';
import Image from './common/image';
import Anchor from './common/anchor';
import Socials from './common/socials';

const Footer = ({ isGlobal }) => {
	const socials = [
		{
			name: 'main twitter account',
			className: 'fab fa-twitter',
			link: 'https://twitter.com/bcntoken',
		},
		{
			name: 'discord',
			className: 'fab fa-discord',
			link: 'https://discord.io/bemfoundation',
		},
		{
			name: 'instagram',
			className: 'fab fa-instagram',
			link: 'https://www.instagram.com/bcntoken/',
		},
		{
			name: 'telegram channel',
			className: 'fab fa-telegram-plane',
			link: 'https://t.me/bcntoken',
		},
		{
			name: 'youtube channel',
			className: 'fab fa-youtube',
			link: 'https://www.youtube.com/@bemfoundation',
		},
	];

	if (!isGlobal) return null;

	return (
		<footer>
			<div className='my-footer'>
				<div className='container pt-5'>
					<div className='row mx-sm-5'>
						<div className='col-md-3 mb-5 mb-lg-0'>
							<Link to='/'>
								<Image
									height='80px'
									fileName='logo_org.png'
								/>
							</Link>
							<p className='mt-3 mb-5'>
								The safest and most reliable way to invest in the cryptocurrency
								market
							</p>
							<Socials
								data={socials}
								navClass='justify-content-start pb-5'
								iconsClass='socialIcons me-2'
							/>
						</div>

						<div className='col-md-3 mb-5 mb-lg-0 text-center'>
							<div>
								<b>Usefull Links</b>
							</div>
							<hr />
							<div>
								<Anchor
									className='text-white'
									href='https://bems.market'
									content='BEMS.MARKET'
								/>
							</div>
							<div>
								<Anchor
									className='text-white'
									href='https://tronscan.org/#/token20/TAoA331n3iKDkR62kAZ4H2n3vNL7y3d8x9/code'
									content='BCN Contract'
								/>
							</div>
							<div>
								<Anchor
									className='text-white'
									href='https://sunswap.com/#/home?tokenAddress=TAoA331n3iKDkR62kAZ4H2n3vNL7y3d8x9&type=swap'
									content='Buy BCN'
								/>
							</div>
							<div>
								<Anchor
									className='text-white'
									href='https://bcntoken.com'
									content='bcntoken.com'
								/>
							</div>
							<div>
								<Anchor
									className='text-white'
									href='https://tronscan.org/#/token20/TQH4JFjzGnWswNeaCxFJi3pSqmsvTXULY8/code'
									content='TIM Contract'
								/>
							</div>
							<div>
								<Anchor
									className='text-white'
									href='https://sunswap.com/#/home?tokenAddress=TQH4JFjzGnWswNeaCxFJi3pSqmsvTXULY8&type=swap'
									content='Buy TIM'
								/>
							</div>
							<div>
								<Anchor
									className='text-white'
									href='https://timtoken.io'
									content='timtoken.io'
								/>
							</div>
							<div>
								<Link
									className='text-white'
									to='/mmiQuery'>
									MMI Query
								</Link>
							</div>
						</div>

						<div className='col-md-3 mb-5 mb-lg-0 text-center'>
							<div>
								<b>Services</b>
							</div>
							<hr />
							<div>Investment</div>
							<div>Insurance</div>
							<div>Lending</div>
							<div>Stock Market</div>
							<div>Store</div>
							<div>NFT Collection </div>
							<div>Forex Market</div>
						</div>

						<div className='col-md-3 mb-5 mb-lg-0 text-center'>
							<h4 className='mb-3'>Contact Information</h4>
							<p>
								<i className='me-2 fas fa-home'></i>MMP Organization
							</p>
							<p>
								<i className='me-2 fas fa-envelope'></i>
								contact@mmp.world
							</p>
							<hr />

							<div>
								<Anchor
									className='text-white'
									href='https://bemfoundation.com'
									content={
										<div className='mt-2'>
											Powered By: <br />
											<Image
												height='25px'
												fileName='bemLogo.png'
											/>{' '}
											BEM Foundation
										</div>
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='my-footer-bottom py-3'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 text-center'>
							<span>
								© {new Date().getFullYear()} BEM FOUNDATION, Inc. All rights
								reserved.
							</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
