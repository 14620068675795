import React from 'react';
import { capitalize } from 'lodash';

const DownlinesTable = ({ users, onSort, sortColumn }) => {
	const headers = [
		{ label: 'No', path: 'no' },
		{ label: 'Bemchain Date', path: 'bemchainDate' },
		{ label: 'ID' },
		{ label: 'MMP Date', path: 'mmpDate' },
		{ label: 'MMI Date', path: 'mmiDate' },
		{ label: 'Email' },
		{ label: 'Name' },
		{ label: 'Position' },
		{ label: 'Level', path: 'level' },
		{ label: 'MMP Card' },
		{ label: 'MMP Value', path: 'currentPackageValue' },
		{ label: 'MMI Start Amount', path: 'mmiStartAmount' },
		{ label: 'Active' },
	];

	const getSortIcon = (column) => {
		if (column.path !== sortColumn.path) return null;
		if (sortColumn.order === 'asc') return <i className='ms-1 text-success fas fa-sort-up'></i>;
		return <i className='ms-1 text-danger fas fa-sort-down' />;
	};

	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						{headers.map((h, key) => (
							<th
								key={key}
								onClick={() => onSort(h.path)}
								style={h.path ? { cursor: 'pointer' } : {}}>
								<div className='d-flex justify-content-center align-items-center'>
									{h.label} {getSortIcon(h)}
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{users.map((user, key) => (
						<tr key={key}>
							<td>{user.no}</td>
							<td>{new Date(user.bemchainDate).toLocaleDateString()}</td>
							<td>{user.id}</td>
							<td>
								{user.mmpDate
									? new Date(user.mmpDate).toLocaleDateString()
									: 'Inactive'}
							</td>
							<td>
								{user.mmiDate
									? new Date(user.mmiDate).toLocaleDateString()
									: 'Inactive'}
							</td>
							<td>{user.email}</td>
							<td>{user.name || 'Unset'}</td>
							<td>{user.position}</td>
							<td>{user.level}</td>
							<td>{capitalize(user.card)}</td>
							<td>
								{user.active ? `$${user.currentPackageValue / 1e6}` : 'Inactive'}
							</td>
							<td>
								{user.mmiStartAmount ? `$${user.mmiStartAmount / 1e6}` : 'Inactive'}
							</td>
							<td>{user.active}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default DownlinesTable;
