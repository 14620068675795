import React from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';

class WalletRequestModalForm extends Form {
	state = {
		data: {
			securityPassword: '',
		},
		errors: {},
	};

	schema = {
		securityPassword: Joi.string().required().label('Security Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, hasSP } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				{this.renderSubmitBtnWithSPass({
					lg: false,
					divClass: 'mt-3',
					hasSP,
					isLoading,
					text: 'Submit',
					loadingText: 'Loading...',
				})}
			</form>
		);
	}
}

export default WalletRequestModalForm;
