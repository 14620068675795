import openSocket from 'socket.io-client';
import config from '../config';

class Socket {
	constructor(server) {
		this.socket = openSocket(server, {
			reconnect: true,
			withCredentials: true,
		});
		this.bemsSocket = openSocket(config.bemsSocketServer, {
			reconnect: true,
			withCredentials: true,
		});
	}

	emitGetAllTokensPrices = () => this.bemsSocket.emit('allTokensPricePing');

	submitSocket = (userID, email) => this.socket.emit('newUser', { userID, email });
	disconnectSocket = () => this.socket.disconnect();
}

const socket = new Socket(config.socketServer);
export default socket;
