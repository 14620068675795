import React from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';
import Spinner from '../../common/spinner';

class GetUserForm extends Form {
	state = {
		data: {
			key: this.props.userMainProps.map((a) => a.id)[0],
			value: '',
		},
		errors: {},
	};

	schema = {
		key: Joi.string()
			.valid(...this.props.userMainProps.map((a) => a.id))
			.required()
			.label('Property'),
		value: Joi.string().required().label('Value'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user) {
			const state = { ...this.state };
			state.data.value = '';
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, userMainProps } = this.props;
		return (
			<form
				className='row gy-3 justify-content-center'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-md-3 col-12'>
					{this.renderSelectNormal({
						name: 'key',
						options: userMainProps.map((a) => a.id),
					})}
				</div>
				<div className='col-md-5 col-12'>
					{this.renderInput({
						name: 'value',
						placeholder: 'Enter the value',
					})}
				</div>
				<div className='col-md-2 col-12 text-center'>
					{this.renderButton(
						'Get User',
						`btn btn-my-navy`,
						isLoading && <Spinner content='Loading...' />
					)}
				</div>
			</form>
		);
	}
}

export default GetUserForm;
