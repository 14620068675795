import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import http from '../../services/httpService';
import MainContext from '../../context/mainContext';
import NavBar from '../navbar';
import Preloader from '../common/preloader';
import Image from '../common/image';

const Confirm = (props) => {
	const { initData, userData, onUserDataChanged } = useContext(MainContext);
	const [message, setMessage] = useState(<h4>Account is being confirmed, please wait...</h4>);
	const isSignedin = !!http.getCurrentUser();

	useEffect(() => {
		if (userData.emailStatus === 'Active') {
			return props.history.replace('/');
		}

		async function fetchData() {
			if (!initData.emailGap || (isSignedin && !userData._id)) return;
			try {
				const confirmationCode = props.match.params.confirmationCode;
				const { data } = await http.confirm({ confirmationCode });
				onUserDataChanged(data.data);
				setMessage(
					<div>
						<h1>Congratulations!!</h1>
						<h4>{data.message}</h4>
					</div>
				);
				toast.success(data);
				return props.history.replace('/');
			} catch (error) {
				console.log('error :>> ', error);
				if (error.response && error.response.data) {
					setMessage(<h4>{error.response.data}</h4>);
				}
			}
		}

		fetchData();
	}, [initData, userData, isSignedin, props, onUserDataChanged]);

	if (!initData.emailGap || (isSignedin && !userData._id)) return <Preloader />;
	return (
		<>
			<NavBar />
			<main className='container mt-5'>
				<div className='row my-1'>
					<div className='col-md-10 offset-md-1 col text-center'>
						<Image
							className='py-5 w-25'
							fileName='logo512.png'
						/>
						{message}
					</div>
				</div>
			</main>
		</>
	);
};

export default Confirm;
