import React, { useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import { RiseLoader } from 'react-spinners';
import config from '../../../../config';
import styles from '../../../../css/dashboard/sponsor.module.css';
import useMain from '../../../../hooks/useMain';
import getCSSVars from '../../../../utils/getCSSVars';
import { paginate } from '../../../../utils/paginate';
import Image from '../../../common/image';
import { BinaryCard, TextCard } from '../../ingredients/cards';
import { numberWithCommas } from '../../../../utils/helpers';

const SponsorGenealogy = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const { initData, userData, userDownlines } = useMain();
	const { L, R, binaryHistory } = userData;

	const sorted = userData.binaryHistory.sort((a, b) => (a.date > b.date ? 1 : -1));
	for (const i in sorted) sorted[i].no = Number(i) + 1;

	const data = paginate(sorted.reverse(), currentPage, config.tablePageSize);

	const getChartData = useMemo(() => {
		const chartData = {
			label: [],
			left: [],
			right: [],
		};

		const sorted = binaryHistory.sort((a, b) => (a.date > b.date ? 1 : -1));
		for (const i in sorted) {
			chartData.label.push(`D-${Number(i) + 1}`);
			chartData.left.push(sorted[i].leftBalance / 1e6);
			chartData.right.push(sorted[i].rightBalance / 1e6);
		}
		return chartData;
	}, [binaryHistory]);

	if (!userDownlines)
		return (
			<div className='h-50 d-flex justify-content-center align-items-center my-5'>
				<RiseLoader
					speedMultiplier={0.8}
					loading={true}
					size={20}
					sizeUnit='px'
					color={getCSSVars('my-purple')}
				/>
			</div>
		);

	const totalLeftUsers = userDownlines.left.filter((u) => u.email).length;
	const activeLeftUsers = userDownlines.left.filter((u) => u.active === 'Yes').length;
	const totalRightUsers = userDownlines.right.filter((u) => u.email).length;
	const activeRightUsers = userDownlines.right.filter((u) => u.active === 'Yes').length;

	return (
		<>
			<TextCard
				titleClass={''}
				title={''}
				text={
					<>
						<div className='row'>
							<div className='col text-center'>
								<div>
									Overall Balance:{' '}
									{numberWithCommas(Math.min(L.totalValue, R.totalValue) / 1e6)}
								</div>
								<h4>Next Binary Distribution Time:</h4>
								<Countdown
									className='h2'
									onComplete={() => window.location.reload()}
									date={initData.binaryTime + 15000}
								/>
							</div>
						</div>
						<hr />
						<div className='row'>
							<div className='col text-center'>
								<div className='fw-bold fs-6 text-decoration-underline'>
									Overall Balance:{' '}
									{numberWithCommas(Math.min(L.totalValue, R.totalValue) / 1e6)}
								</div>
							</div>
						</div>
						<div className='row mt-5'>
							<div className='col-xl-4 offset-xl-1 col-lg-6 col-12 mb-4 text-end'>
								<div className={`mb-4 myCards card ${styles.inCard}`}>
									<div className='pt-5 pb-3 fs-6 pe-5'>Total Left Investment</div>
									<Image
										className={`${styles.cardIconLeft}`}
										fileName={'icons/sponsor/totalLeft.png'}
									/>
									<div
										className={`card-img-bottom ${styles.totalCardsBottom_left}`}>
										<div className='py-2 fs-3 me-5'>
											<b>${numberWithCommas(L.totalValue / 1e6)}</b>
										</div>
									</div>
								</div>
							</div>

							<div className='col-xl-4 offset-xl-2 col-lg-6 col-12 mb-4'>
								<div className={`mb-4 myCards card ${styles.inCard}`}>
									<div className='pt-5 pb-3 fs-6 ps-5'>
										Total Right Investment
									</div>
									<Image
										className={`${styles.cardIconRight}`}
										fileName={'icons/sponsor/totalRight.png'}
									/>
									<div
										className={`card-img-bottom ${styles.totalCardsBottom_right}`}>
										<div className='py-2 fs-3 ms-5'>
											<b>${numberWithCommas(R.totalValue / 1e6)}</b>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-xl-4 offset-xl-1 col-lg-6 col-12 mb-4 text-end'>
								<div className={`mb-4 myCards card ${styles.inCard}`}>
									<div className='pt-5 pb-3 fs-6 pe-5'>Daily Left Investment</div>
									<Image
										className={`${styles.cardIconLeft}`}
										fileName={'icons/sponsor/dailyLeft.png'}
									/>
									<div className={`card-img-bottom ${styles.dailyCardsBottom}`}>
										<div className='py-2 fs-3 me-5'>
											<b>${numberWithCommas(L.unPaid / 1e6)}</b>
										</div>
									</div>
								</div>
							</div>

							<div className='col-xl-4 offset-xl-2 col-lg-6 col-12 mb-4'>
								<div className={`mb-4 myCards card ${styles.inCard}`}>
									<div className='pt-5 pb-3 fs-6 ps-5'>
										Daily Right Investment
									</div>
									<Image
										className={`${styles.cardIconRight}`}
										fileName={'icons/sponsor/dailyRight.png'}
									/>
									<div className={`card-img-bottom ${styles.dailyCardsBottom}`}>
										<div className='py-2 fs-3 ms-5'>
											<b>${numberWithCommas(R.unPaid / 1e6)}</b>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-12 mb-4 text-center'>
								<div className={`mb-4 myCards card ${styles.balanceCard}`}>
									<div className='pt-5 pb-3 fs-6'>Daily Balance:</div>
									<Image
										className={`${styles.cardIconCenter}`}
										fileName={'icons/sponsor/balance.png'}
									/>
									<div className={`card-img-bottom ${styles.balanceCardsBottom}`}>
										<div className='py-2 fs-3'>
											<b>
												$
												{numberWithCommas(
													Math.min(L.unPaid, R.unPaid) / 1e6
												)}
											</b>
										</div>
									</div>
								</div>
							</div>
						</div>

						{userDownlines ? (
							<div className='row'>
								<div className='col-xl-4 offset-xl-1 col-lg-6 col-12 mb-4'>
									<div className={`mb-4 card ${styles.personCard}`}>
										<div
											className='pt-5 pb-0 fs-6'
											style={{ paddingLeft: '6rem' }}>
											Active Users / Total Users (Left)
										</div>

										<div
											className={`card-img-bottom ${styles.personCardBottom}`}>
											<div
												className='py-3'
												style={{ marginLeft: '6rem' }}>
												<b className='fs-2'>
													{activeLeftUsers} / {totalLeftUsers}
												</b>
												<div className='opacity-50'>Persons</div>
											</div>
											<Image
												className={`${styles.personCardIconRight}`}
												fileName={'icons/sponsor/leftUsers.png'}
											/>
										</div>
									</div>
								</div>

								<div className='col-xl-4 offset-xl-2 col-lg-6 col-12 mb-4 text-end'>
									<div className={`mb-4 card ${styles.personCard}`}>
										<div
											className='pt-5 pb-0 fs-6'
											style={{ paddingRight: '6rem' }}>
											Active Users / Total Users (Right)
										</div>

										<div
											className={`card-img-bottom ${styles.personCardBottom}`}>
											<div
												className='py-3'
												style={{ marginRight: '6rem' }}>
												<b className='fs-2'>
													{activeRightUsers} / {totalRightUsers}
												</b>
												<div className='opacity-50'>Persons</div>
											</div>
											<Image
												className={`${styles.personCardIconLeft}`}
												fileName={'icons/sponsor/rightUsers.png'}
											/>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className='h-50 d-flex justify-content-center align-items-center'>
								<RiseLoader
									speedMultiplier={0.8}
									loading={true}
									size={20}
									sizeUnit={'px'}
									color={'#7b8cff'}
								/>
							</div>
						)}
					</>
				}
			/>

			{sorted.length > 0 && (
				<BinaryCard
					data={data}
					itemsCount={userData.binaryHistory.length}
					pageSize={config.tablePageSize}
					currentPage={currentPage}
					onPageChange={setCurrentPage}
					chartData={getChartData}
				/>
			)}
		</>
	);
};

export default SponsorGenealogy;
