/**
 * Converts your flat binary tree data (with "left" and "right" arrays)
 * into a nested tree structure expected by react-d3-tree.
 *
 * Assumptions:
 * - Each node has a "wallet" field (used as its unique id) and a "parent" field.
 * - Nodes whose "parent" value is not found among the nodes are children of the root.
 *
 * @param {Object} data - Your data object with keys "left" and "right"
 * @returns {Object} A nested tree with a dummy root node.
 */
export default function convertToD3Structure(data, rootData, maxDepth) {
	// Combine the two arrays into one list
	const allNodes = [...(data.left || []), ...(data.right || [])].filter(
		(n) => n.depth < maxDepth
	);

	// Build a map from wallet to our converted node structure.
	// We'll include the original node under a "data" key if you want more details.
	const nodeMap = new Map();
	allNodes.forEach((node) => {
		nodeMap.set(node.wallet, {
			name: node.wallet, // You might use node.id or any other field here.
			data: node,
			childrenCount: node.directs.length,
			children: [],
		});
	});

	// Create a dummy root node.
	// Any node whose parent is not in our map will be attached to this root.
	const root = {
		name: allNodes.length > 0 ? allNodes[0].parent : 'Root',
		children: [],
		data: rootData,
		childrenCount: 0,
	};

	// Now, loop over all nodes to attach them to their parent if found,
	// otherwise attach them to the dummy root.
	allNodes.forEach((node) => {
		const convertedNode = nodeMap.get(node.wallet);
		if (nodeMap.has(node.parent)) {
			const parentNode = nodeMap.get(node.parent);
			parentNode.children.push(convertedNode);
		} else {
			// Parent not found, so this node is a direct child of the root.
			root.childrenCount++;
			root.children.push(convertedNode);
		}
	});

	return root;
}
