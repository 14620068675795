import React from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useMain from '../../hooks/useMain';
// import { addWorkingDays } from '../../utils/helpers';
import { roundUpMinutes } from '../../utils/helpers';
import useWithdrawals from './../../hooks/useWithdrawals';
import Preloader from './../common/preloader';
import WithdrawChart from './withdraw/withdrawChart';
import CumTable from './withdraw/cumTable';

function WithdrawRequests(props) {
	useDocumentTitle(['Withdraw Requests']);
	const { initData } = useMain();
	const [withdrawals] = useWithdrawals();
	if (!withdrawals) return <Preloader />;

	const { result, balance } = withdrawals;
	const users = result
		.sort((a, b) => (a.request.date > b.request.date ? 1 : -1))
		.map((u) => ({
			...{
				// sendTime: addWorkingDays(
				// 	new Date(roundUpMinutes(u.request.date)),
				// 	initData.withdrawGap
				// ).getTime(),
				sendTime: new Date(roundUpMinutes(u.request.date)).getTime() + initData.withdrawGap,
				amount: u.request.amount / 1e6,
			},
			...u,
		}));

	const reducedData = users.reduce((acc, u) => {
		const index = acc.findIndex((a) => a.time === u.sendTime);
		if (index === -1) acc.push({ time: u.sendTime, amount: u.amount, count: 1 });
		else {
			acc[index].amount += u.amount;
			acc[index].count++;
		}
		return acc;
	}, []);

	let cumCounter = 0;
	const cummData = reducedData.map((a, i) => {
		cumCounter += a.amount;
		return {
			...a,
			...{ cum: cumCounter, hour: Number(i) + 1 },
		};
	});

	const chartData_time = cummData.map((d) => {
		const myDate = new Date(d.time);
		const month = myDate.getMonth() + 1;
		const day = myDate.getDate();
		const hour = myDate.getHours();
		return `${day}/${month}, ${hour}:00`;
	});
	const chartData_amount = cummData.map((d) => d.amount);

	const total = users.reduce((a, b) => a + b.request.amount, 0);
	const isEnough = balance > total;

	const limit = 200;
	const limit_01 = users.slice(0, limit).reduce((a, b) => a + b.request.amount, 0);
	const limit_02 = users
		.slice((2 - 1) * limit, limit * 2)
		.reduce((a, b) => a + b.request.amount, 0);
	const limit_03 = users
		.slice((3 - 1) * limit, limit * 3)
		.reduce((a, b) => a + b.request.amount, 0);
	const limit_04 = users
		.slice((4 - 1) * limit, limit * 4)
		.reduce((a, b) => a + b.request.amount, 0);

	let rechargePointIndex = undefined;
	let counter = 0;
	if (!isEnough) {
		for (let i = 0; i < chartData_amount.length; i++) {
			const amount = chartData_amount[i];
			counter += amount * 1e6;
			if (counter > balance) {
				rechargePointIndex = i;
				break;
			}
		}
	}

	return (
		<>
			<div className='container-fluid mt-4'>
				<div className='row gy-5 align-items-center'>
					<div className='col-12'>
						<div className='fw-bold fs-4 text-center'>Withdraw Requests</div>
					</div>
					<div className='col-sm-4 text-center mb-3'>
						<div>
							Total Requests: <b>{users.length}</b>
						</div>
						<div className='text-purple fs-5'>
							Hot Wallet Balance: <b>{balance / 1e6}</b> USDT
						</div>
						<div className={`text-${isEnough ? 'success' : 'danger'} fs-5`}>
							Total Requests Amount: <b>{total / 1e6}</b> USDT
						</div>

						{!isEnough && (
							<div>
								Recharge Wallet Before: <b>{chartData_time[rechargePointIndex]}</b>{' '}
							</div>
						)}

						<div>
							Next Limit: <b>{limit_01 / 1e6}</b> - <b>{limit_02 / 1e6}</b> -{' '}
							<b>{limit_03 / 1e6}</b> - <b>{limit_04 / 1e6}</b>
						</div>

						<div className='row mt-5'>
							<div className='offset-sm-2 col-sm-8'>
								<CumTable
									data={cummData}
									rechargePointIndex={rechargePointIndex}
								/>
							</div>
						</div>
					</div>
					<div className='col-sm-8'>
						<WithdrawChart
							data={{
								time: chartData_time,
								amount: chartData_amount,
								rechargePointIndex,
							}}
						/>
					</div>
				</div>
			</div>
			<div className='container my-4'>
				<div className='row gy-5'>
					<div className='col-12 text-center'>
						<div className='table-responsive'>
							<table className='table table-sm myTable text-center'>
								<thead>
									<tr>
										<th>#</th>
										<th>UID</th>
										<th>Email</th>
										<th>Request Date</th>
										<th>Send Date</th>
										<th>Amount (USDT)</th>
									</tr>
								</thead>
								<tbody>
									{users.map((u, i) => (
										<tr
											className={
												cummData.findIndex((d) => d.time === u.sendTime) %
													2 ===
												0
													? 'bg-gray'
													: 'bg-white'
											}
											key={i}>
											<td>{Number(i) + 1}</td>
											<td>{u.uid}</td>
											<td>{u.email}</td>
											<td>{new Date(u.request.date).toLocaleString()}</td>
											<td>{new Date(u.sendTime).toLocaleString()}</td>
											<td>{u.amount}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default WithdrawRequests;
