import React from 'react';
import Image from '../../../../common/image';

const CustomNode = ({ nodeDatum, toggleNode, onLabelClick }) => {
	const getFillColor = (card) => {
		if (!card) return '#777777';
		const cards = ['white', 'purple', 'blue', 'red', 'black', 'royal'];
		const colors = ['#fff', '#a020e9', '#1badde', '#fc0000', '#4e4e4e', '#d3a43c'];
		return colors[cards.indexOf(card)];
	};

	return (
		<g>
			<circle
				r='15'
				fill={getFillColor(nodeDatum.data?.card)}
				stroke='#000'
				strokeWidth='1'
				onClick={toggleNode}
				style={{ cursor: 'pointer' }}
			/>
			<text
				fill='#000'
				x='-25'
				dy='5'
				strokeWidth='0'
				style={{ fontSize: '12px', fontFamily: 'Arial', pointerEvents: 'none' }}>
				{nodeDatum.childrenCount}
			</text>

			<foreignObject
				x='20'
				y='-30'
				width='100'
				height='500'>
				<div
					xmlns='http://www.w3.org/1999/xhtml'
					style={{
						background: '#fff',
						border: '1px solid #ccc',
						borderRadius: '3px',
						fontFamily: 'Roboto',
						fontSize: '15px',
						lineHeight: '20px',
						padding: '0 5px',
						pointerEvents: 'all',
						cursor: 'pointer',
					}}
					onClick={(e) => {
						e.stopPropagation();
						onLabelClick(nodeDatum.data);
					}}>
					<div>{nodeDatum.data?.id}</div>
					{nodeDatum.data.email && (
						<div className='d-flex justify-content-evenly align-items-center'>
							<Image
								height='35px'
								fileName={`diamonds/mmi_${nodeDatum.data?.mmiIsActive}.png`}
							/>
							<Image
								height='35px'
								fileName={`diamonds/${nodeDatum.data?.card}.png`}
							/>
						</div>
					)}
				</div>
			</foreignObject>
		</g>
	);
};

export default CustomNode;
